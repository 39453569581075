import useBaseHook from 'themes/hooks/BaseHooks'
import { Result, Button } from 'antd';

const Error = ({ permission, requirePermission, userPermission }
    : { permission?: string, requirePermission?: string, userPermission?: string }) => {
    const { t, router } = useBaseHook()
    let subTitle
    if (permission) {
        subTitle = t("pages:errors.403.permissionDetail", {permission, requirePermission, userPermission})
    }
    return <Result
        status="403"
        title={t("pages:errors.403.title")}
        subTitle={<div>{t("pages:errors.403.description")}<br />{subTitle}</div>}
        extra={<Button type="primary" onClick={() => router.back()}>{t("back")}</Button>}
    />
}

Error.getInitialProps = () => {
    return {
        namespacesRequired: ['common', 'pages', 'menu']
    }
}
export default Error
